import React,{useEffect, useState} from 'react'

import { motion } from "framer-motion";
import { useDispatch,useSelector } from "react-redux";
import "../styles/components/Contact.scss";
import ContactSVG from "../assets/contact.svg";
import { contactFormValidator } from '../utils/formValidation';
import { contact_us } from '../redux/features/basoitSlice';
import LoadingSpinner from './LoadingSpinner';
import {toast } from 'react-toastify';
import Navbar from './Navbar';



const inititalformData = {
  name: "",
  email: "",
  mobile: "",
  title:"",
  message: ""
}
const ContactUs = () => {

  const [formData, setFormData] = useState(inititalformData);
  const [formErrors, setformErrorData] = useState({});
  const dispatch=useDispatch();

const {loading}=useSelector((state)=>state.basoit);
  const handleChange = async (e) => {
  
    e.preventDefault();
    const { name, value } = e.target;
    await setFormData({ ...formData, [name]: value })


  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setformErrorData({})
    const temp = await contactFormValidator(formData);
    setformErrorData({...formErrors,...temp})
debugger
   if (Object.keys(temp).length === 0) {
     dispatch(contact_us(formData))
     setFormData({})
     setformErrorData({})
   }

  }


  return (
     <section className="contact">
      {
        loading ? <LoadingSpinner/> :<>
         <motion.img 
       initial={{
        x: "-50vw",
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      transition={{ delay: 0.3 }}
       src={ContactSVG} style={{width:400}}/>
      <motion.form
       method='POST'
        initial={{
          x: "50vw",
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{ delay: 0.2 }}
        onSubmit={(e)=>handleSubmit(e)}
      >
        <h2>Contact Us</h2>
        <input type="text" name='name' placeholder="Full Name" required onChange={handleChange}/>
        <p>{formErrors.name}</p>
        <input type="email" name='email' placeholder="Email" required onChange={handleChange}/>
        <p>{formErrors.email}</p>
        <input type={'tel'} name='mobile' maxLength='10' required  placeholder="Mobile"  onChange={handleChange}/>
        <p>{formErrors.mobile}</p>
        <input type="text" name='title' placeholder="Title" required onChange={handleChange}/>
        <p>{formErrors.title}</p>

        <textarea name='message' placeholder="Message..." cols="30" rows="10" onChange={handleChange}></textarea>
        

        <button type="submit">Send</button>
      </motion.form>

      <motion.div
        className="formBorder"
        initial={{
          x: "100vw",
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{ delay: 0.2 }}
      >
        <motion.div
          initial={{
            y: "-100vh",
            x: "50%",
            opacity: 0,
          }}
          animate={{
            x: "50%",
            y: "-50%",
            opacity: 1,
          }}
          transition={{
            delay: 1,
          }}
        >
          {/* <img src={burger} alt="Burger" /> */}
        </motion.div>
      </motion.div>
        </>
      }
     
    </section>
  )
}

export default ContactUs
