import React from "react";
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import { useScroll } from "../components/useScroll";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { GoPlay } from "react-icons/go";
import { motion } from "framer-motion";
import WorkImage from "../assets/work.svg";
import "../styles/sections/Starter.scss";
import { headerAnimation, imageAnimation } from "../utils/Animations";

export default function Starter() {
  const [element, controls] = useScroll();

  return (
    <div className="main-container" ref={element}>
      <Navbar />
      <div className="container">
        <motion.div
          className="content"
          variants={headerAnimation}
          animate={controls}
          transition={{ delay: 0.2, type: "tween" }}
        >
          <h1>
            We provide solutions to help you to build and grow your buisness.
          </h1>
          <p>
           Huge collection of professional service provider at one place to server you with high quality work. You can enjoy shopping 
           from top brands at your fingure tip. We also provide entertainment services by providing free and high quality games. 
          </p>
          
          <div className="button-container">
            {/* <Button content="Watch Video" icon={<GoPlay />} /> */}
            {/* <Button
              color="pink"
              content="Contact Us"
              icon={<HiOutlineArrowNarrowRight />}
            /> */}
          </div>
        </motion.div>
        <motion.div
          className="image"
          variants={imageAnimation}
          animate={controls}
          transition={{ type: "tween" }}
        >
          <img src={WorkImage} alt="Work Image" />
        </motion.div>
      </div>
    </div>
  );
}
