import React from "react";
import Button from "../components/Button";
import Card from "../components/Card";
import Title from "../components/Title";
import { useScroll } from "../components/useScroll";
import { HiLightBulb } from "react-icons/hi";
import { BsFillCalendarFill } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { SiGooglemessages } from "react-icons/si";
import { motion } from "framer-motion";
import "../styles/sections/About.scss";
import { reveal } from "../utils/Animations";

function About() {
  const [element, controls] = useScroll();

  return (
    <div className="about-container" id="about" ref={element}>
      <div className="container">
        <motion.div
          className="details"
          initial="hidden"
          animate="show"
          animate={controls}
          variants={reveal}
          transition={{ delay: 0.1, stiffness: 300 }}
        >
          <Title title="About BASOIT" color="blue" />
          <p>
            We Believe that everyone deserves equal opportunities to grow in the era of digital world.
            Innovation, simplicity and dedication are keys  make us happy. Our mission is to help
            people achieve what they have passionate about it. 
          </p>
          
          <p>
            We are excited to build a strong relation with amazimg mind like you.
          </p>
          <p>
           Our moto is to support our local vendor to do their business online by providing high quality service and support and also provide platform in Basoit to display them
          </p>
        </motion.div>
        <div className="cards" ref={element}>
          <Card
            title="Innovative Ideas"
            logo={<HiLightBulb />}
            animateCustom={controls}
          />
          <Card
            title="NIT Alumni"
            logo={<BsFillCalendarFill />}
            animateCustom={controls}
          />
          <Card
            title="Experienced Team"
            logo={<BiSupport />}
            animateCustom={controls}
          />
          <Card
            title="Latest Tech Stack"
            logo={<SiGooglemessages />}
            animateCustom={controls}
          />
          <Card
            title="Competative price"
            logo={<BiSupport />}
            animateCustom={controls}
          />
          <Card
            title="24 * 7 Support"
            logo={<SiGooglemessages />}
            animateCustom={controls}
          />
        </div>
      </div>
    </div>
  );
}

export default About;
