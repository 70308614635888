import React from "react";
import Service from "../components/Services";
import Button from "../components/Button";
import Title from "../components/Title";
import { useScroll } from "../components/useScroll";
import { motion } from "framer-motion";
import blockchain from "../assets/blockchain_development.jpg";
import digitalMarketing from "../assets/digital_marketing.jpg";
import ecommerce from "../assets/ecommerce.png";
import games from "../assets/games.jpg";
import mobileApplicationDevelopment from "../assets/mobile_application_development.jpg";
import softwareSolution from "../assets/software_solution.jpg";
import webApplicationDevelopment from "../assets/web_application_development.png";
import "../styles/sections/Services.scss";
import { textAnimation, cardAnimation } from "../utils/Animations";

function Services() {
  const [element, controls] = useScroll();
  
  return (
    <div className="services-container" id="services" ref={element}>
      <div className="container">
        <motion.div
          className="title-container"
          variants={textAnimation}
          animate={controls}
          transition={{ duration: 1 }}
        >
          <Title title="Services" color="pink" lineCenter={true} />
          <p>Insights and advice from our experts.</p>
        </motion.div>
        <div className="services">
        <Service
            image={softwareSolution}
            title="Software Solution"
            variants={cardAnimation}
            animate={controls}
          />
           
          <Service
            image={mobileApplicationDevelopment}
            title="Mobile Application Development"
        
            variants={cardAnimation}
            animate={controls}
          />
          <Service
            image={games}
            title="Games"
            variants={cardAnimation}
            animate={controls}
          />
       
          <Service
            image={ecommerce}
            title="Ecommerce"
        
            variants={cardAnimation}
            animate={controls}
          />
          <Service
            image={webApplicationDevelopment}
            title="Web Application Development"
            variants={cardAnimation}
            animate={controls}
          />
          <Service
            image={digitalMarketing}
            title="Digital Marketing"
            variants={cardAnimation}
            animate={controls}
          />
            
            <Service
            image={blockchain}
            title="Blockchain & Cryptocurrency"
            variants={cardAnimation}
            animate={controls}
          />
           
           
          
        </div>
        <div
          className="button-container"
          variants={textAnimation}
          animate={controls}
        >
          {/* <Button content="View All" /> */}
        </div>
      </div>
    </div>
  );
}

export default Services;
