

export const registrationFormValidator=(fornData,errorData)=>{


    if (!fornData.email) {
        errorData.email="Email id is required!!"
   
    } else if (!fornData.email.match(/\S+@\S+\.\S+/)) {
      errorData.email="Please enter a valid email"
    }
    else{
        errorData={};
    }


    if (!fornData.name) {
      errorData.name="Full name is required!!"
    }else{
        errorData={};
    }

    if (!fornData.mobile) {
    
      errorData.mobile="Phone number is required!!"

    }else{
        errorData={};
    }

    if (!fornData.password) {
      errorData.password="Password is required!!"
  
    } else if (fornData.password.length < 5) {
      errorData.password="Password length should be atleast 5"

    }else{
        errorData={};
    }


    if (!fornData.confirm_password ) {
        errorData.confirm_password="Passwords not match!!"
       
      } else if (fornData.confirm_password == fornData.password) {
        errorData.confirm_password="Passwords not match!!"
       
      }else{
        errorData={};
    }
return errorData;
}



export const contactFormValidator= async (fornData)=>{
  debugger;
let errorData={};

  if (!fornData.email) {
      errorData.email="Email id is required!!"
 
  } else if (!fornData.email.match(/\S+@\S+\.\S+/)) {
    errorData.email="Please enter a valid email"
  }


  if (!fornData.name) {
    errorData.name="Full name is required!!"
  }


  if (!fornData.title) {
    errorData.title="Title is required!!"
  }

  if (!fornData.mobile) {
    errorData.mobile="Phone number is required!!"
  }else if (!fornData.mobile.match(/(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g  )) {
    errorData.mobile="Please enter a valid mobile number"
  }


return errorData;
}