
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../Api'
import { toast } from 'react-toastify';


const initialState = {
    loading:false

}
export const contact_us = createAsyncThunk('basoit/contact_us', async (formData) => {

    try {
        const response = await api.contactUs(formData);
      
        return response.data;

    } catch (error) {
        toast.error("erroe")
        // ShowToast({message:error.response.data.message,title:"Error 😞",type:"error"})
        
    }

})
export const basoitSlice = createSlice({
    name: 'basoit',
    initialState,
    reducers:{},
    
 
    extraReducers: {
        [contact_us.fulfilled]:  (state, action) => {  
            state.loading=false;
          
            toast.success("Thanks for Reaching us.Our team will get back you soon.")
            
        },
        [contact_us.pending]:  (state) => { 
            state.loading=true  
        },
        [contact_us.rejected]:  (state,payload) => {
            state.loading=false 
          
        },
    }

})


export default basoitSlice.reducer;