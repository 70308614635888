import axios from "axios";

const API = axios.create({
    baseURL: "https://backend.basoit.com/"

});

export const getShopping = () => API.get('/shoppings');

//Post
export const contactUs = (data) => API.post('/contactUs/savecontactUs',data);