import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Redux Store

import { Provider } from 'react-redux';

import { store } from "./redux/store";

import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
  HashRouter
  
} from "react-router-dom";
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "privacy_policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "contact",
    element: <ContactUs />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ToastContainer/>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);


