import React from "react";
import About from "./sections/About";
import Footer from "./sections/Footer";
import Starter from "./sections/Starter";
import Why from "./sections/Why";
import Services from "./sections/Services";
import ScrollToTop from "./components/ScrollToTop";
import { motion } from "framer-motion";






function App() {
  return (
<motion.div initial="hidden" animate="show">
      <ScrollToTop />
      <Starter />
      <About />
      <Services/>
      <Why />
      <Footer />
    </motion.div>
   
  );
}

export default App;
