import { configureStore,compose } from '@reduxjs/toolkit'
import BasoitReducer from './features/basoitSlice';
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    basoit: BasoitReducer,
});



export const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

